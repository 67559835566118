import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Loader = ({ fontSize = '20px', showText = true, text = 'Loading...' }) => (
  <div
    style={{
      fontSize: fontSize,
      color: 'rgba(10,10,10,.87)',
      margin: '0.5em auto',
    }}
  >
    <FontAwesomeIcon spin icon={faSpinner} /> {showText ? text : ''}
  </div>
)

export default Loader
